<template>
  <v-dialog v-model="dialog" persistent width="930">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card :disabled="loading" :loading="loading" class="custom-card-bg-gradient">
        <DialogHeader :is-close="true" :title="'Add to tasking'" @close="dialog = false" />
        <v-card-text>
          <div class="pt-3" style="width: 100%;">
            <div style="width: 100%">
              <v-layout align-center>
                <div style="width: 300px">
                  <DateRangerPicker v-model="orderService.dates" label="Select time"/>
                </div>
                <div class="mx-1" style="width: 250px">
                  <v-autocomplete
                    v-model="orderService.image_source"
                    :items="imageSources"
                    :rules="[rules.required]"
                    dense
                    hide-details
                    item-text="name"
                    label="Select source"
                    outlined
                    placeholder="Select source"
                    return-object
                  />
                </div>
                <div class="mx-1" style="width: 250px">
                  <v-autocomplete
                    v-model="orderService.frequency"
                    :items="frequency"
                    :rules="[rules.required]"
                    dense
                    hide-details
                    item-text="name"
                    label="Select frequency"
                    outlined
                    placeholder="Select frequency"
                    return-object
                  />
                </div>
                <v-spacer />
                <v-btn color="primary" fab small @click="addToList">
                  <v-icon>icon-plus</v-icon>
                </v-btn>
              </v-layout>
            </div>
          </div>
          <v-data-table
            :headers="headers"
            :items="data"
            :loading="loading"
            class="hide-per-page table-small-text mt-4"
            fixed-header
            height="200"
          >
            <template #[`item.service_name`]="{item}">
              {{ item.service.name }}
            </template>
            <template #[`item.image_source`]="{item}">
              {{ item.image_source.name }}
            </template>
            <template #[`item.time`]="{item}">
              <div>{{ item.dates[0] }} - {{ item.dates[1] }}</div>
            </template>
            <template #[`item.action`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="info" icon x-small v-bind="attrs" v-on="on" @click="editOrder(item)">
                    <v-icon>icon-edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="error" icon x-small v-bind="attrs" v-on="on" @click="removeOrder(item)">
                    <v-icon>icon-trash</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" rounded type="submit" @click="submit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { mapState } from '@/store/ults'
import sleep from '@/utils/sleep'
import { getAvailableServices } from '@/api/services'

import DateRangerPicker from '@/components/DateRangerPicker.vue'

export default {
  name: 'Task',
  components: { DateRangerPicker, DialogHeader },
  data() {
    return {
      imageSources: [
        {
          name: 'SAR',
          frequency: ['once', 'weekly', 'monthly'],
        },
        {
          name: 'RGB Optical',
          frequency: ['once', 'weekly', 'monthly'],
        },
      ],
      headers: [
        { text: 'Service', value: 'service_name', sortable: false },
        { text: 'Frequency', value: 'frequency', sortable: false },
        { text: 'Source', value: 'image_source', sortable: false },
        { text: 'Time', value: 'time', sortable: false },
        { text: 'Action', value: 'action', sortable: false },
      ],
      data: [],
      orderService: {
        service: {},
        frequency: undefined,
        image_source: undefined,
        dates: [],
        steps: undefined,
        other_info: undefined,
      },
      properties: {},
      menuService: false,
      keyword: undefined,
      dialog: false,
      valid: false,
      AOI: {},
      allService: [],
      availableServices: [],
      availableAOI: [],
      rules: {
        required: value => !!value || 'Item is required',
      },
      loading: false,
    }
  },
  computed: {
    ...mapState('AOI', ['listAOI', 'groups']),
    ...mapState('service', ['services']),
    frequency() {
      if (!this.orderService.image_source) return []
      return this.orderService.image_source.frequency
    },
  },
  watch: {
    dialog(val) {
      if (val) this.initForm()
      else
        this.orderService = {
          service: {},
          frequency: undefined,
          image_source: undefined,
          dates: [],
        }
      this.data = []
    },
    menuService(val) {
      if (!val) {
        this.keyword = undefined
        this.searchService()
      }
    },
  },
  methods: {
    openDialog(AOI) {
      this.AOI = AOI
      this.dialog = true
    },
    changeService(service) {
      this.orderService.service = { ...service }
      this.orderService.frequency = undefined
      this.orderService.image_source = undefined
      this.menuService = false
    },
    searchService() {
      if (!this.keyword) this.availableServices = [...this.allService]
      else {
        this.availableServices = this.allService.filter(
          val =>
            val.services.some(service => service.name.toLowerCase().includes(this.keyword.toLowerCase())) ||
            val.name.toLowerCase().includes(this.keyword.toLowerCase()),
        )
      }
    },
    addToList() {
      this.$refs.formData.validate()
      if (this.valid) this.data.push({ ...this.orderService })
    },
    editOrder(order) {
      this.orderService = { ...order }
      this.removeOrder(order)
    },
    removeOrder(order) {
      let ind = this.data.findIndex(val => val.toString() === order.toString())
      if (ind >= 0) this.data.splice(ind, 1)
    },
    async initForm() {
      if (!this.listAOI.length) {
        await sleep(500)
        return []
      } else this.getListAOI(this.listAOI)
      // await this.getServices()
    },
    async getServices() {
      try {
        this.loading = true
        this.availableServices = []
        const res = await getAvailableServices({
          projectId: this.$route.params.id,
          id: this.AOI.uuid,
        })
        res.data.forEach(val => {
          val.checked = false
        })
        res.data.forEach(service => {
          let group = this.availableServices.find(group => group.name === service.group)
          if (!group) {
            this.availableServices.push({
              name: service.group,
              services: [service],
            })
          } else group.services.push(service)
          this.allService = [...this.availableServices]
        })
        this.firstLoad = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    getListAOI(arr) {
      for (let i = 0; i < arr.length; i++) {
        if (!arr[i].items) this.availableAOI.push(arr[i])
        else this.getListAOI(arr[i].items)
      }
    },
    async submit() {
      try {
        if (!this.data.length) return this.$store.commit('message/SHOW_ERROR', 'Please! Add order to list')
        this.loading = true
        let data = {
          projectId: this.$route.params.id,
          id: this.AOI.uuid,
          params: {
            services: [],
          },
        }
        this.data.forEach(order => {
          data.params.services.push({
            id: order.service.id,
            from_date: order.dates[0],
            to_date: order.dates[1],
            image_source: order.image_source.name,
            frequency: order.frequency,
            steps: order.steps,
            other_info: order.other_info,
          })
        })
        // await makeOrder(data)
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        this.dialog = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped></style>
