<template>
  <v-layout style="flex: 1; overflow-x: hidden">
    <v-row class="ma-0">
      <v-col class="py-0 pr-2 overflow-y-hidden" cols="12" md="4" style="height: 100%">
        <v-layout class="fill-height overflow-y-hidden" column>
          <v-layout class="pb-2 overflow-y-hidden" style="flex: 2">
            <v-layout class="overflow-y-hidden" column fill-height>
              <v-card class="custom-card-bg-1 overflow-y-auto" height="100%" width="100%">
                <div class="fill-height pa-5" style="width: 100%">
                  <v-row v-if="Object.keys(statistic).length">
                    <v-col class="py-2 pr-2" cols="12" md="12" style="height: 96px">
                      <v-card class="custom-card-bg-1" height="100%" width="100%">
                        <v-layout align-center class="fill-height px-6" justify-center>
                          <v-layout align-center class="fill-height" justify-center>
                            <div
                              class="pr-2"
                              style="color: var(--v-primary-base) !important; font-size: 26px; font-weight: bold"
                            >
                              Total Change:
                            </div>
                            <v-spacer />
                            <v-layout column justify-end>
                              <div class="pb-1" style="font-size: 1vw; font-weight: bold; text-align: end">
                                {{ transformArea(statistic.overview.total.area) }}
                              </div>
                              <div class="pb-1" style="font-size: 13px; font-weight: bold; text-align: end">
                                Features:
                                {{ numberFormat.numberWithCommas(statistic.overview.total.count) }}
                              </div>
                            </v-layout>
                          </v-layout>
                        </v-layout>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-if="Object.keys(statistic).length && statistic.overview">
                    <v-col
                      v-for="(item, key) of statistic.overview.others"
                      :key="key"
                      class="py-2 pr-2"
                      cols="12"
                      md="6"
                      style="height: 96px; cursor: pointer"
                      @click="setFilterType(key)"
                    >
                      <v-card class="custom-card-bg-1 position-relative" height="100%" width="100%">
                        <border-arrow v-if="type === key" />
                        <v-layout align-center class="fill-height" column justify-center>
                          <div style="font-size: 0.95vw; font-weight: bold" v-bind:style="{ color: item.color }">
                            {{ key }}
                          </div>
                          <div style="font-size: 0.8vw; font-weight: bold">
                            {{ transformArea(item.area) }} - {{ item.area_percent }}%
                          </div>
                          <div style="font-size: 13px">
                            Total feature:
                            {{ numberFormat.numberWithCommas(item.count) }}
                          </div>
                        </v-layout>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-layout>
          </v-layout>
          <v-layout class="pt-2 overflow-y-hidden fill-height" column style="flex: 3">
            <v-card
              class="custom-card-bg-1 overflow-y-auto overflow-x-hidden"
              height="100%"
              style="text-align: center"
              width="100%"
            >
              <v-layout v-show="Object.keys(statistic).length" align-center class="fill-height">
                <v-row class="pa-5">
                  <v-col cols="12" md="6" style="border-right: 1px solid #272c48">
                    <div v-if="Object.keys(statistic).length && statistic.classificationData.length">
                      {{ statistic.classificationData[1].date }}
                    </div>
                    <div style="flex: none; overflow-y: auto; flex-direction: column; font-size: 0.85vw">
                      <v-layout
                        v-if="Object.keys(statistic).length && statistic.classificationData.length"
                        column
                        style="flex: 1"
                      >
                        <div
                          v-for="(item, index) in statistic.classificationData[1].statistics"
                          :key="index"
                          class="py-2"
                          style="flex: none; min-height: 0; height: fit-content"
                        >
                          <v-layout align-center class="fill-height">
                            <div v-bind:style="{ color: item.color }">
                              {{ item.label }}
                            </div>
                            <v-spacer />
                            <div v-bind:style="{ color: item.color }">
                              {{ transformArea(item.area) }}
                            </div>
                          </v-layout>
                        </div>
                      </v-layout>
                    </div>
                    <v-layout class="pt-7" justify-center>
                      <canvas id="chart" height="200" width="200"></canvas>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" md="6" style="border-left: 1px solid #272c48">
                    <div v-if="Object.keys(statistic).length && statistic.classificationData.length">
                      {{ statistic.classificationData[0].date }}
                    </div>
                    <div style="flex: none; overflow-y: auto; flex-direction: column; font-size: 0.85vw">
                      <v-layout
                        v-if="Object.keys(statistic).length && statistic.classificationData.length"
                        column
                        style="flex: 1"
                      >
                        <div
                          v-for="(item, index) in statistic.classificationData[0].statistics"
                          :key="index"
                          class="py-2"
                          style="flex: none; min-height: 0; height: fit-content"
                        >
                          <v-layout align-center class="fill-height">
                            <div v-bind:style="{ color: item.color }">
                              {{ item.label }}
                            </div>
                            <v-spacer />
                            <div v-bind:style="{ color: item.color }">
                              {{ transformArea(item.area) }}
                            </div>
                          </v-layout>
                        </div>
                      </v-layout>
                    </div>
                    <v-layout class="pt-7" justify-center>
                      <canvas id="chartCompare" height="200" width="200"></canvas>
                    </v-layout>
                  </v-col>
                </v-row>
              </v-layout>
            </v-card>
          </v-layout>
        </v-layout>
      </v-col>
      <v-col class="py-0 pl-2 pr-0 overflow-y-hidden" cols="12" md="8" style="height: 100%">
        <v-layout class="fill-height" column>
          <v-layout class="pb-2 overflow-y-hidden" style="flex: 3">
            <CardMapView ref="map" :inspect="true" />
          </v-layout>
          <v-layout class="pt-2 overflow-y-hidden" style="flex: 2">
            <v-card class="custom-card-bg-1 overflow-y-hidden" height="100%" width="100%">
              <v-data-table
                :headers="headers"
                :items="changeData"
                :loading="dataLoading"
                :options.sync="options"
                :server-items-length="totalData"
                class="hide-per-page table-small-text fill-height"
                fixed-header
                height="calc(100% - 50px)"
              >
                <template #[`item.area`]="{ item }">
                  <div v-html="transformArea(item.area)"></div>
                </template>
                <template #[`item.properties.type`]="{ item }">
                  <div v-html="item.properties.type"></div>
                </template>
                <template #[`item.action`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading || loading"
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="addLayer(item)"
                      >
                        <v-icon>icon-gps</v-icon>
                      </v-btn>
                    </template>
                    <span>Highlight</span>
                  </v-tooltip>
                  <v-tooltip v-if="currentUser.permissions.includes('tasking_order.submit')" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading || loading"
                        icon
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        @click="openCreateOrderForm(item)"
                      >
                        <v-icon>mdi-satellite</v-icon>
                      </v-btn>
                    </template>
                    <span>Add to Tasking</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-layout>
        </v-layout>
      </v-col>
    </v-row>
    <Task ref="task" />
    <CreateOrder ref="createOrder" />
  </v-layout>
</template>
<script>
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import { getType } from '@/api/order'
import { getChangeDetectionLayer, getFeature, getOverview, getVector } from '@/api/change-detection-api'
import { mapState } from '@/store/ults'
import VectorStyle from '@/utils/VectorStyle'
import bbox from '@turf/bbox'
import sleep from '@/utils/sleep'
import AreaConvert from '@/utils/textArea'
import numberFormat from '@/utils/comma'
import Chart from 'chart.js/auto'
import BorderArrow from '@/components/BorderArrow.vue'
import Task from '@/components/task/Task.vue'
import CreateOrder from '@/views/createOrder/CreateOrder.vue'

export default {
  components: { CreateOrder, Task, BorderArrow, CardMapView, numberFormat },
  data() {
    return {
      chartData: [],
      labels: [],
      chart: undefined,
      chartCompare: undefined,
      statistic: {},
      dataLoading: false,
      loading: false,
      editing: false,
      types: [],
      type: undefined,
      layers: [],
      editFeature: undefined,
      leftMenu: true,
      totalData: 0,
      changeData: [],
      options: {},
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'properties.type',
        },
        {
          text: 'Area',
          align: 'start',
          sortable: true,
          value: 'area',
        },
        {
          text: 'Action',
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ],
    }
  },
  props: {
    aoi: {},
    selectedService: {
      type: Object,
      default: () => {},
    },
    selectedResult: {
      type: Object,
      default: () => {},
    },
    selectedSource: {},
  },
  computed: {
    ...mapState('auth', ['currentUser']),
    numberFormat() {
      return numberFormat
    },
    ...mapState('AOI', ['AOIs']),
    sources() {
      return this.service.sources
    },
    service: {
      get() {
        return this.selectedService
      },
      set(val) {
        this.$emit('update:selectedService', val)
      },
    },
    currentAOI: {
      get() {
        return this.aoi
      },
      set(val) {
        this.$emit('update:aoi', val)
      },
    },
    source: {
      get() {
        return this.selectedSource
      },
      set(val) {
        this.$emit('update:selectedSource', val)
      },
    },
    result: {
      get() {
        return this.selectedResult
      },
      set(val) {
        this.$emit('update:selectedResult', val)
      },
    },
  },
  mounted() {
    this.options.sortBy[0] = 'area'
    this.options.sortDesc[0] = true
    this.changeMapMode('slide')
  },
  watch: {
    editing(val) {
      if (!val) {
        this.editFeature = undefined
        this.$refs.map.$refs.map.resetDraw()
        this.changeMapMode('slide')
      }
    },
    options: {
      handler() {
        this.getData()
      },
      deep: true,
    },
    leftMenu() {
      this.$refs.map.$refs.map.reSize()
    },
    result(val) {
      if (!val || !Object.keys(val).length) {
        this.$refs.map.removeAllLayer()
        if (this['chart']) {
          this['chart'].data.datasets = []
          this['chart'].data.labels = []
          this['chart'].update()
        }
        if (this['chartCompare']) {
          this['chartCompare'].data.datasets = []
          this['chartCompare'].data.labels = []
          this['chartCompare'].update()
        }
        this.changeData = []
        this.statistic = {}
      }
    }
  },
  methods: {
    async openCreateOrderForm(data) {
      try {
        this.loading = true
        let featureDetail = await this.getFeature(data)
        featureDetail.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: featureDetail.geojson,
            },
          ],
        }
        this.$refs.createOrder.openDialog({
          geojson: featureDetail.geojson,
          dataType: 'vector-result',
          name: data.name,
          id: data.id,
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    setFilterType(type) {
      if (this.type === type) this.type = undefined
      else this.type = type
      this.getData()
    },
    async changeMapMode(mode) {
      if (!this.$refs.map) {
        await sleep(100)
        await this.changeMapMode(mode)
      } else {
        await sleep(500)
        this.$refs.map.$refs.layerControl.mapMode = mode
      }
    },
    zoomTo(bbox) {
      this.$refs.map.zoomTo(bbox)
    },
    async getType() {
      try {
        this.loading = true
        const res = await getType({ serviceId: this.service.service_id })
        this.types = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    filterData() {
      if (!this.currentAOI || !this.service || !this.source || !this.result || !this.result.uuid) return
      this.getData()
      this.getLayers()
      this.getStatistic()
    },
    async getStatistic() {
      try {
        this.loading = true
        const res = await getOverview({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            vector_uuid: this.result.uuid,
            showable: true,
          },
        })
        this.statistic = res.data
        if (!this.chart) {
          this.displayChart(this.statistic.classificationData[1], 'chart')
          this.displayChart(this.statistic.classificationData[0], 'chartCompare')
        } else {
          this.transformData(this.statistic.classificationData[1])
          this.updateChartData(this.chartData, 'chart')
          this.transformData(this.statistic.classificationData[0])
          this.updateChartData(this.chartData, 'chartCompare')
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getData() {
      try {
        if (!this.result.uuid) return
        this.dataLoading = true
        // this.changeData = []
        const res = await getVector({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          payload: {
            showable: true,
            type: this.type,
            page: this.options.page,
            per_page: this.options.itemsPerPage,
            sort: this.options.sortBy[0]
              ? !this.options.sortDesc[0]
                ? this.options.sortBy[0]
                : `-${this.options.sortBy[0]}`
              : undefined,
          },
        })
        this.changeData = res.data.data
        this.totalData = res.data.total
      } catch (e) {
      } finally {
        this.dataLoading = false
      }
    },
    async getLayers() {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        const res = await getChangeDetectionLayer({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          payload: { source: this.source },
        })
        res.data.forEach((val, index) => {
          if (index === res.data.length - 1) val.tile_info.name = val.name
          if (val.tile_info.type === 'images') {
            let image = {
              id: val.name,
              display: index === res.data.length - 1,
              data: val.tile_info,
              layerType: 'Raster_Image',
              name: val.name,
              tiles: [val.tile_info.tile_url],
              bounds: val.tile_info.bbox,
            }
            this.$refs.map.$refs.map.addImageToMap(image, undefined, false)
          } else {
            this.$refs.map.$refs.map.addVectorTiles(
              {
                isHover: true,
                display: index === res.data.length - 1,
                data: val.tile_info,
                tiles: [val.tile_info.tile_url],
                bounds: val.tile_info.bbox,
                paint: VectorStyle.getStyle(val.tile_info.styles),
                layerName: 'default',
                name: val.name,
                id: val.name,
                type: val.tile_info.styles.type,
              },
              false,
            )
          }
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async addLayer(feature) {
      let featureDetail = await this.getFeature(feature)
      this.$refs.map.$refs.map.displayVector(
        '#ff0000',
        featureDetail.geojson,
        'line',
        '#ff0000',
        'change-id-' + featureDetail.id,
        false,
        'polygon',
      )
      this.$refs.map.zoomTo(bbox(featureDetail.geojson))
      await sleep(800)
      this.$refs.map.$refs.map.removeLayer('change-id-' + featureDetail.id)
    },
    async getFeature(feature) {
      try {
        this.loading = true
        const res = await getFeature({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          vectorId: this.result.uuid,
          featureId: feature.id,
          payload: {},
        })
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    cancelEdit() {
      this.editing = false
    },
    transformArea(area, withUnit = true) {
      return AreaConvert.covertArea(area, 'ha', withUnit)
    },
    resetData() {
      this.source = undefined
      this.changeData = []
      this.service = {}
      this.result = undefined
    },
    transformData(classificationData) {
      this.chartData = []
      this.labels = []
      this.labels = classificationData.statistics.map(val => val.label)
      this.chartData.push({
        label: classificationData.date,
        data: classificationData.statistics.map(val => AreaConvert.covertNumberArea(val.area, 'ha')),
        backgroundColor: classificationData.statistics.map(val => val.color),
      })
    },
    displayChart(classificationData, chartId) {
      this.transformData(classificationData)
      const data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      let ctx = document.getElementById(chartId)
      this[chartId] = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: window.innerHeight > 350 ? 'right' : 'top',
            },
          },
          title: {
            display: true,
            text: classificationData.date,
          },
          scales: {},
          tooltips: {
            callbacks: {
              label: function (tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
    updateChartData(newData, chartId) {
      this[chartId].data.datasets = newData
      this[chartId].data.labels = this.labels
      this[chartId].update()
    },
  },
}
</script>

<style scoped></style>
