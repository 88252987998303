<template>
  <v-layout class="fill-height overflow-x-hidden overflow-y-auto" column>
    <div class="pa-2" style="height: fit-content; width: 100%; flex: none">
      <v-row style="height: fit-content; overflow-y: hidden">
        <v-col cols="12" md="4" style="height: fit-content; text-align: center">
          <v-btn-toggle v-model="menu" color="deep-purple accent-3" group mandatory style="width: 100%" tile>
            <v-btn min-width="0" style="font-size: 0.65vw" value="overview" width="33%">
              Overview
            </v-btn>

            <v-btn min-width="0" style="font-size: 0.65vw" value="results" width="33%">
              Top 10 Results
            </v-btn>

            <v-btn min-width="0" style="font-size: 0.65vw" value="compare" width="33%">
              Compare Changes
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" md="8" style="height: fit-content;">
          <v-row>
            <v-col cols="12" md="3" sm="12">
              <v-select
                v-model="currentAOI"
                :disabled="editing"
                :items="AOIs"
                :loading="loading"
                dense
                hide-details
                item-text="name"
                label="Select AOI"
                outlined
                placeholder="AOI"
                return-object
                @change="getService"
              />
            </v-col>
            <v-col cols="12" md="3" sm="12">
              <v-select
                v-model="service"
                :disabled="!currentAOI || editing"
                :items="services"
                :loading="loading"
                dense
                hide-details
                item-text="service_name"
                label="Select service"
                outlined
                placeholder="Service"
                return-object
                @change="getType"
              />
            </v-col>
            <v-col cols="12" md="3" sm="12">
              <v-select
                v-model="source"
                :disabled="!Object.keys(service).length || editing"
                :items="sources"
                :loading="loading"
                dense
                hide-details
                item-text="name"
                label="Select source"
                outlined
                placeholder="Source"
                return-object
                @change="getListResult"
              />
            </v-col>
            <v-col cols="12" md="3" sm="12">
              <v-select
                v-model="result"
                :disabled="!source || editing"
                :items="data"
                :loading="loading"
                dense
                hide-details
                item-text="date"
                label="Date"
                outlined
                placeholder="Date"
                return-object
                @change="filterData"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <Overview
      v-show="menu === 'overview'"
      ref="overview"
      :aoi.sync="currentAOI"
      :selected-result.sync="result"
      :selected-service.sync="service"
      :selected-source.sync="source"
    />
  </v-layout>
</template>
<script>
import CardMapView from '@/views/change-detection/CardData/CardMapView.vue'
import { getOrder, getType } from '@/api/order'
import { getChangeDetectionResult } from '@/api/change-detection-api'
import { mapState } from '@/store/ults'
import sleep from '@/utils/sleep'
import Overview from '@/views/change-detection/dashboard/Overview.vue'

export default {
  components: { Overview, CardMapView },
  data() {
    return {
      menu: 'overview',
      dataLoading: false,
      loading: false,
      editing: false,
      types: [],
      type: undefined,
      layers: [],
      source: undefined,
      editFeature: undefined,
      currentAOI: undefined,
      services: [],
      service: {},
      data: [],
      result: {},
      totalData: 0,
    }
  },
  computed: {
    ...mapState('AOI', ['AOIs']),
    sources() {
      return this.service.sources
    },
  },
  mounted() {
    this.getListAOI()
  },
  watch: {
    service(val) {
      if (val) {
        this.source = this.sources ? this.sources[0] : undefined
        this.getListResult()
      }
    },
  },
  methods: {
    async getType() {
      try {
        this.loading = true
        const res = await getType({ serviceId: this.service.service_id })
        this.types = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_group: this.$route.meta.service },
        })
        this.currentAOI = this.AOIs[0]
        await this.getService()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getService() {
      try {
        this.loading = true
        this.resetData()
        this.$refs[this.menu].zoomTo(this.currentAOI.bbox)
        const res = await getOrder({ projectId: this.$route.params.id, id: this.currentAOI.uuid })
        this.services = res.data.filter(val => val.service_group === this.$route.meta.service)
        let tmpServices = []
        this.services.forEach(service => {
          let index = tmpServices.findIndex(val => val.service_name === service.service_name)
          if (index < 0) {
            service.sources = [service.image_source]
            tmpServices.push(service)
          } else tmpServices[index].sources.push(service.image_source)
        })

        this.services = []
        this.services = tmpServices
        this.service = this.services[0]
        if (this.service) {
          this.source = this.service.sources[0]
          await this.getListData()
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListResult() {
      try {
        this.loading = true
        this.data = []
        const res = await getChangeDetectionResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            service_id: this.service.service_id,
            source: this.source,
            per_page: 'all',
          },
        })
        this.data = res.data
        this.result = res.data[0] ? res.data[0] : {}
        if (Object.keys(this.result).length) await this.filterData()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async filterData() {
      await sleep(500)
      await this.$refs[this.menu].filterData()
    },
    resetData() {
      this.source = undefined
      this.services = []
      this.service = {}
      this.data = []
      this.result = {}
    },
  },
}
</script>

<style scoped></style>
